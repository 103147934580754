import { ComponentType, Suspense, lazy, LazyExoticComponent, FC } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { RouteComponentProps, Router } from '@reach/router';

import Layout from 'components/layout';
import Loader from 'components/loader';
import ReduceProviders from 'components/reduce-providers';
import { useAuthContext } from 'state/auth';

import providers from './providers';
import { routes } from './utils/routing';

import './index.css';

type LazyRoute = LazyExoticComponent<ComponentType<any>>;

const CtgLoyalty: LazyRoute = lazy(() => import('./containers/ctg-loyalty'));
const CTGLoyaltyTransaction: LazyRoute = lazy(() => import('./containers/ctg-loyalty-transaction'));
const Customer: LazyRoute = lazy(() => import('./containers/customer'));
const GiftCard: LazyRoute = lazy(() => import('./containers/giftcard'));
const Home: LazyRoute = lazy(() => import('./containers/home'));
const Offers: LazyRoute = lazy(() => import('./containers/offers-unified'));
const Order: LazyRoute = lazy(() => import('./containers/order'));
const Orders: LazyRoute = lazy(() => import('./containers/orders'));
const DeviceOrders: LazyRoute = lazy(() => import('./containers/orders'));
const ReleaseNotes: LazyRoute = lazy(() => import('./containers/release-notes'));
const SignIn: LazyRoute = lazy(() => import('./containers/sign-in'));
const StoreDetails: LazyRoute = lazy(() => import('./containers/store'));
const SupportHistory: LazyRoute = lazy(() => import('./containers/support-history'));

const NotFound: FC<RouteComponentProps> = () => <p>Sorry, nothing here</p>;

const ContentRouting = () => {
  const { isAuthenticated } = useAuthContext();

  return (
    <Suspense fallback={<Loader />}>
      {isAuthenticated() ? (
        <Router primary={false}>
          <Home path={routes.default} />

          <Customer path={routes.customer} />
          <Customer path={`${routes.customer}/:customerId`} />

          <DeviceOrders path={routes.deviceOrders} />
          <DeviceOrders path={`${routes.deviceOrders}/:deviceId`} />

          <Orders path={routes.orders} />
          <Orders path={`${routes.customer}/:customerId${routes.orders}`} />
          <Order path={`${routes.customer}/:customerId${routes.orders}/:orderId`} />

          <GiftCard path={routes.giftCard} />
          <GiftCard path={`${routes.giftCard}/:isoCountryCode/:cardNumber`} />
          <GiftCard path={`${routes.customer}/:customerId${routes.giftCard}`} />

          <Offers path={routes.offers} key={routes.offers} />
          <Offers path={`${routes.customer}/:customerId${routes.offers}`} />
          <CtgLoyalty path={routes.ctgLoyalty} />
          <CtgLoyalty path={`${routes.customer}/:customerId${routes.ctgLoyalty}`} />
          <CTGLoyaltyTransaction
            path={`${routes.customer}/:customerId${routes.ctgLoyaltyTransaction}/:transactionId`}
          />

          <StoreDetails path={routes.store} />
          <StoreDetails path={`${routes.store}/:storeId`} />

          <ReleaseNotes path={routes.releaseNotes} />
          <ReleaseNotes path={`${routes.releaseNotes}/:date`} />

          <SupportHistory path={routes.supportHistory} />
          <SupportHistory path={`${routes.customer}/:customerId${routes.supportHistory}`} />
          <NotFound default />
        </Router>
      ) : (
        <SignIn />
      )}
    </Suspense>
  );
};

const App = () => {
  return (
    <ReduceProviders providers={providers}>
      <CssBaseline />
      <Layout>
        <ContentRouting />
      </Layout>
    </ReduceProviders>
  );
};

export default App;
