import { Brand as AllBrands, IsoCountryCode } from '@rbilabs/plk-common';
import { Trie } from '../../structures/trie';

type SupportedBrand = Exclude<AllBrands, AllBrands.TH>;

export interface IUserRole {
  email?: string;
  roles?: Role[];
  userName: string;
}

export const buisinessRoles = ['supervisor', 'agent', 'bot'];
export const databaseRoles = [
  'can-admin',
  'che-admin',
  'deu-admin',
  'gbr-admin',
  'usa-admin',
  'zaf-admin',
  'nzl-admin',
] as const;

export const allRoles = [...databaseRoles, ...buisinessRoles];

export const limits = ['points', 'refundAmount', 'refundDays'] as const;
export type Role = (typeof allRoles)[number];
export type LimitType = (typeof limits)[number];

/* eslint-disable no-shadow */
export enum Permission {
  MergeBalance = 'RBI.supportActions.giftCard.merge',
  ReplaceBalance = 'RBI.supportActions.giftCard.replaceBalance',
  IssueLoyaltyPoints = 'RBI.supportActions.loyalty.issuePoints',
  RefundOrders = 'RBI.supportActions.orders.refund',
  CreateAccount = 'RBI.supportActions.accounts.create',
  AuditAccount = 'RBI.supportActions.accounts.auditAccount',
  //Database Superpermission
  ReadDB = 'RBI.database.read',
  WriteDB = 'RBI.database.write',
  //Region Subpermissions
  ReadCanDB = 'RBI.database.read.can',
  ReadCheDB = 'RBI.database.read.che',
  ReadDeuDB = 'RBI.database.read.deu',
  ReadGbrDB = 'RBI.database.read.gbr',
  ReadNzlDB = 'RBI.database.read.nzl',
  ReadUsaDB = 'RBI.database.read.usa',
  ReadZafDB = 'RBI.database.read.zaf',
  WriteCanDB = 'RBI.database.write.can',
  WriteCheDB = 'RBI.database.write.che',
  WriteDeuDB = 'RBI.database.write.deu',
  WriteGbrDB = 'RBI.database.write.gbr',
  WriteNzlDB = 'RBI.database.write.nzl',
  WriteUsaDB = 'RBI.database.write.usa',
  WriteZafDB = 'RBI.database.write.zaf',
}

/* eslint-disable sort-keys */
export const rolePermissionMapping: Record<SupportedBrand, Record<Role, string[]>> = {
  BK: {
    bot: ['RBI.supportActions.loyalty.issuePoints', 'RBI.supportActions.orders.refund'],
    agent: ['RBI.supportActions.orders.*', 'RBI.supportActions.loyalty.*'],
    supervisor: ['RBI.supportActions.*'],
    //Database Roles
    'can-admin': ['RBI.database.read.can', 'RBI.database.write.can'],
    'che-admin': ['RBI.database.read.che', 'RBI.database.write.che'],
    'deu-admin': ['RBI.database.read.deu', 'RBI.database.write.deu'],
    'gbr-admin': ['RBI.database.read.gbr', 'RBI.database.write.gbr'],
    'nzl-admin': ['RBI.database.read.nzl', 'RBI.database.write.nzl'],
    'usa-admin': ['RBI.database.read.usa', 'RBI.database.write.usa'],
    'zaf-admin': ['RBI.database.read.zaf', 'RBI.database.write.zaf'],
  },
  FHS: {
    bot: ['RBI.supportActions.loyalty.issuePoints', 'RBI.supportActions.orders.refund'],
    agent: ['RBI.supportActions.orders.*', 'RBI.supportActions.loyalty.*'],
    supervisor: ['RBI.supportActions.*'],
    //Database Roles
    'can-admin': ['RBI.database.read.can', 'RBI.database.write.can'],
    'che-admin': ['RBI.database.read.che', 'RBI.database.write.che'],
    'deu-admin': ['RBI.database.read.deu', 'RBI.database.write.deu'],
    'gbr-admin': ['RBI.database.read.gbr', 'RBI.database.write.gbr'],
    'nzl-admin': ['RBI.database.read.nzl', 'RBI.database.write.nzl'],
    'usa-admin': ['RBI.database.read.usa', 'RBI.database.write.usa'],
    'zaf-admin': ['RBI.database.read.zaf', 'RBI.database.write.zaf'],
  },
  LTW: {
    bot: ['RBI.supportActions.loyalty.issuePoints', 'RBI.supportActions.orders.refund'],
    agent: ['RBI.supportActions.orders.*', 'RBI.supportActions.loyalty.*'],
    supervisor: ['RBI.supportActions.*'],
    //Database Roles
    'can-admin': ['RBI.database.read.can', 'RBI.database.write.can'],
    'che-admin': ['RBI.database.read.che', 'RBI.database.write.che'],
    'deu-admin': ['RBI.database.read.deu', 'RBI.database.write.deu'],
    'gbr-admin': ['RBI.database.read.gbr', 'RBI.database.write.gbr'],
    'nzl-admin': ['RBI.database.read.nzl', 'RBI.database.write.nzl'],
    'usa-admin': ['RBI.database.read.usa', 'RBI.database.write.usa'],
    'zaf-admin': ['RBI.database.read.zaf', 'RBI.database.write.zaf'],
  },
  PLK: {
    bot: ['RBI.supportActions.loyalty.issuePoints', 'RBI.supportActions.orders.refund'],
    agent: ['RBI.supportActions.orders.*', 'RBI.supportActions.loyalty.*'],
    supervisor: ['RBI.supportActions.*'],
    //Database Roles
    'can-admin': ['RBI.database.read.can', 'RBI.database.write.can'],
    'che-admin': ['RBI.database.read.che', 'RBI.database.write.che'],
    'deu-admin': ['RBI.database.read.deu', 'RBI.database.write.deu'],
    'gbr-admin': ['RBI.database.read.gbr', 'RBI.database.write.gbr'],
    'nzl-admin': ['RBI.database.read.nzl', 'RBI.database.write.nzl'],
    'usa-admin': ['RBI.database.read.usa', 'RBI.database.write.usa'],
    'zaf-admin': ['RBI.database.read.zaf', 'RBI.database.write.zaf'],
  },
};

//Limitation mapping, infinity means no limit
const roleLimitMapping: Record<Role, Record<LimitType, number>> = {
  agent: { points: Infinity, refundAmount: Infinity, refundDays: 30 },
  bot: { points: Infinity, refundAmount: Infinity, refundDays: 30 },
  supervisor: { points: Infinity, refundAmount: Infinity, refundDays: Infinity },
};

const permissionTrie = new Trie();

//Flattens the permissions to one flat list containing all user permissions.
export function getUserPermissions(roles: Role[]): string[] {
  const hasNoBusinessRoles = !roles.find((role) => buisinessRoles.includes(role));
  if (hasNoBusinessRoles) {
    // default role is bot if user has no business roles assigned.
    roles.push('bot');
  }
  const brand = (process.env.REACT_APP_RBI_BRAND?.toUpperCase() ?? 'PLK') as SupportedBrand;
  permissionTrie.addAll(Object.values(Permission));
  let userPermissions: string[] = [];
  roles?.forEach((role) => {
    //Add all permissions from group to user
    rolePermissionMapping?.[brand]?.[role]?.forEach((permission) => {
      userPermissions = [
        ...userPermissions,
        ...permissionTrie.search(permission.replace('.*', '')),
      ];
    });
  });
  return [...new Set(userPermissions)];
}

//Gets the maximum limitations for a given user.
export function getUserLimits(roles: Role[]): Record<LimitType, number> {
  const userLimits: Record<LimitType, number> = { points: 0, refundAmount: 0, refundDays: 0 };
  for (const role of roles) {
    if (buisinessRoles.includes(role)) {
      for (const limit in roleLimitMapping[role] ?? []) {
        if (limit in userLimits) {
          userLimits[limit as LimitType] = Math.max(
            userLimits[limit as LimitType],
            roleLimitMapping[role][limit as LimitType],
          );
        }
      }
    }
  }
  return userLimits;
}

//Populates all the roles into a Trie object.
export function getPermissionTrie(): Trie {
  permissionTrie.addAll(Object.values(Permission));
  return permissionTrie;
}

export function hasRegionalPermission({
  user,
  region,
}: {
  user: IUserRole;
  region: IsoCountryCode;
}): boolean {
  const userPermissions = getUserPermissions(user?.roles ?? []);
  return userPermissions.includes(Permission.ReadDB.valueOf() + `.${region.toLowerCase()}`);
}
