import {
  Person,
  LocalMallOutlined,
  StoreOutlined,
  CardGiftcardOutlined,
  Devices,
} from '@material-ui/icons';
import { capitalize } from 'lodash-es';

import {
  formatGiftCardNumber,
  nameOfMostExpensiveCartEntry,
  currencyToIsoCountryCodeMap,
} from 'components/utils';
import { routes } from 'utils/routing';

import { SearchResult } from './types';

export const searchResultDisplayMap = {
  DeviceOrderListResponse: {
    icon: (_result: SearchResult) => <Devices />,
    tooltip: (_result: SearchResult) => 'Device',
    route: (result: SearchResult) => `${routes.deviceOrders}/${result.id}`,
    display: (_result: SearchResult) => 'Device Found',
  },
  CustomerDetails: {
    icon: (_result: SearchResult) => <Person />,
    tooltip: (_result: SearchResult) => 'Customer',
    route: (result: SearchResult) => `${routes.customer}/${result.id}`,
    display: (result: SearchResult) => 'email' in result && result.email,
  },
  Order: {
    icon: (_result: SearchResult) => <LocalMallOutlined />,
    tooltip: (_result: SearchResult) => 'Order',
    route: (result: SearchResult) =>
      'userId' in result && `${routes.customer}/${result.userId}${routes.orders}/${result.id}`,
    display: (result: SearchResult) => 'cart' in result && nameOfMostExpensiveCartEntry(result),
  },
  PrepaidCard: {
    icon: (_result: SearchResult) => <CardGiftcardOutlined />,
    tooltip: (_result: SearchResult) => 'Gift Card',
    route: (result: SearchResult) => {
      if ('transactions' in result) {
        const countryCode =
          currencyToIsoCountryCodeMap[result.transactions?.[0]?.currencyCode ?? ''] ?? 'unknown';
        return `${routes.giftCard}/${countryCode}/${result.id}`;
      }
    },
    display: (result: SearchResult) => {
      if ('cardNumber' in result && 'transactions' in result) {
        const countryCode =
          currencyToIsoCountryCodeMap[result.transactions?.[0]?.currencyCode ?? ''] ?? 'unknown';
        return `${countryCode} ${formatGiftCardNumber(result?.cardNumber ?? undefined)}`;
      }
    },
  },
  Store: {
    icon: (_result: SearchResult) => <StoreOutlined />,
    tooltip: (result: SearchResult) => `Store ${'storeNumber' in result && result.storeNumber}`,
    route: (result: SearchResult) => `${routes.store}/${result.id}`,
    display: (result: SearchResult) =>
      'storeAddress' in result &&
      'storeNumber' in result &&
      [result.storeAddress?.address1, result.storeAddress?.city]
        .filter(Boolean)
        .join(', ')
        .toLowerCase()
        .split(' ')
        .map((word) => capitalize(word))
        .join(' '),
  },
};
