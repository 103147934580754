import { Person, LocalMall, Store, Redeem, Devices } from '@material-ui/icons';
import { ListItem } from 'design-system';

export const SearchInstructions = () => {
  return (
    <>
      <ListItem iconTooltipText="Customer" icon={<Person />} text="Customer Email or Cognito ID" />
      <ListItem iconTooltipText="Device" icon={<Devices />} text="Device ID" />
      <ListItem iconTooltipText="Order" icon={<LocalMall />} text="RBI Order ID" />
      <ListItem
        iconTooltipText="Store"
        icon={<Store />}
        text="Store Number, Sanity ID or Address"
      />
      <ListItem iconTooltipText="Gift Card" icon={<Redeem />} text="Card Number" />
    </>
  );
};
