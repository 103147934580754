import {
  Devices,
  ListAlt,
  LocalMall,
  LocalOffer,
  People,
  Redeem,
  Stars,
  Store,
} from '@material-ui/icons';

import PLKLogo from 'components/logos/plk';
import { PLK_DARK_ORANGE } from 'state/theme/colors';

import { routes } from '../utils/routing';
import { IBrandConfigs } from './brand-config';

const plkConfigs: IBrandConfigs = {
  links: [
    {
      name: 'Customers',
      route: routes.customer,
      Icon: People,
    },
    {
      name: 'Device Orders',
      route: routes.deviceOrders,
      Icon: Devices,
    },
    {
      name: 'Orders',
      route: routes.orders,
      Icon: LocalMall,
    },
    {
      name: 'Support History',
      route: routes.supportHistory,
      Icon: ListAlt,
    },
    {
      name: 'Gift Cards',
      route: routes.giftCard,
      Icon: Redeem,
    },
    {
      name: 'Offers',
      route: routes.offers,
      Icon: LocalOffer,
    },
    {
      name: 'Loyalty',
      route: routes.ctgLoyalty,
      Icon: Stars,
    },
    {
      name: 'Stores',
      route: routes.store,
      Icon: Store,
    },
  ],
  Logo: PLKLogo,
  theme: {
    brandColor: PLK_DARK_ORANGE,
  },
  hasPhysicalCtgLoyaltyCard: true,
  loyaltyPointsIssuanceRange: {
    USA: {
      min: 1,
      max: 1500,
      step: 1,
    },
    CAN: {
      min: 1,
      max: 1500,
      step: 1,
    },
    DEFAULT: {
      min: 1,
      max: 1500,
      step: 1,
    },
  },
  loyaltyPointsPerCurrency: {
    USA: 100,
    CAN: 0,
    DEFAULT: 100,
  },
  allowPointsOnLoyaltyTab: {
    USA: true,
    CAN: true,
    DEFAULT: true,
  },
};

export default plkConfigs;
