import { ICustomer, IStoreAddress, IStoreDetails } from '.';

enum Platform {
  WEB = 'web',
  APP = 'app',
  KIOSK = 'kiosk',
  GOOGLE = 'google',
}

export interface IFormattedCartItem {
  id: string;
  price: string | null;
  quantity: number;
  name: string;
  depth: number;
  type: string;
  children: IFormattedCartItem[];
}

export interface IOrder {
  createdAt: string;
  cart: ICart;
  rbiOrderId: string;
  posOrderId: string;
  posVendor: string;
  status: string;
  delivery: IDelivery;
  order: IMaybeRefundDetails;
  paymentAuditEvent: IPaymentAuditEvent;
  paymentStatus?: string;
  paymentVersion?: number;
}

export interface IRefundedItem {
  name: string;
  refundAmount: string;
  refundQuantity: string;
}

export interface IMaybeRefundDetails {
  currency: string;
  refund: IRefundDetails | null;
}

export interface IRefundDetails {
  primaryReason: string;
  secondaryReason: string;
  approvedAmount: string;
  refundMethod?: string;
  refundedItems: IRefundedItem[];
}
export interface ICartEntry {
  name: string;
  children: ICartEntry[];
  pickerSelections: string;
  price: number;
  quantity: number;
  sanityId: string;
  type: string;
  url: string;
  productHierarchy?: IProductHierarchy | null;
}

export interface IProductHierarchy {
  L1?: string;
  L2?: string;
  L3?: string;
  L4?: string;
  L5?: string;
}

export interface IPayment {
  cardType: string;
  ccExpiryMonth: string;
  ccExpiryYear: string;
  ccLast4: string;
  fdCorrelationId: string;
  fdCustomerId: string;
  fdSaleId: string;
}

export interface IDeliveryDriver {
  driverId: string;
  name: string;
  phoneNumber: string;
  profileImageUrl: string;
}

export interface IDelivery {
  assignedFleetName?: string;
  dropoff?: {
    beforeTime: string;
  };
  driver?: IDeliveryDriver;
  feeCents: number;
  feeDiscountCents?: number;
  status: string;
  tipCents: number;
}

export interface IDiscount {
  name: string;
  value: number;
}

export interface IDonation {
  id: string;
  name: string;
  totalCents: number;
}

export interface ICart {
  cartEntries: ICartEntry[];
  customerName: string;
  discounts: IDiscount[];
  donations: IDonation[];
  payment: IPayment;
  platform: Platform;
  serviceMode: ServiceMode;
  storeAddress: IStoreAddress;
  storeId: string;
  storeDetails: IStoreDetails;
  subTotalCents: number;
  subTotalPaidCents: number;
  taxCents: number;
  taxPaidCents: number;
  ticketNumber: string;
  totalCents: number;
  totalPaidCents: number;
  userDetails: ICustomer;
  userId?: string;
  deviceId?: string;
}

export interface IOrderList {
  count: number;
  orders: IOrder[];
}

export type UniqueCartItem = {
  parentId: string;
  sanityId: string;
  name: string;
  unitPrice: number;
  tax: number;
  refundAmount: number;
  refundQuantity: 1;
  uniqueCompositeId: string;
  type: string;
};

interface IPaymentAuditEventPrepaidContext {
  balance: {
    beginBalance: number;
    currentBalance: number;
  };
}

interface IPaymentAuditEventContext {
  amountCents: number;
  currency: string;
  failureReason: string;
  fdSaleId: string;
  isSuccessful: boolean;
  prepaid: IPaymentAuditEventPrepaidContext;
  transactionId: string;
  type: string;
}

interface IAuditEvent {
  auditLogId: string;
  createdAt: string;
  eventId: string;
  userId: string;
}

interface IPaymentAuditEvent extends IAuditEvent {
  context: IPaymentAuditEventContext;
}

export enum ServiceMode {
  CATERING_PICKUP = 'CATERING_PICKUP',
  CATERING_DELIVERY = 'CATERING_DELIVERY',
  CURBSIDE = 'CURBSIDE',
  DELIVERY = 'DELIVERY',
  DRIVE_THRU = 'DRIVE_THRU',
  EAT_IN = 'EAT_IN',
  TAKEOUT = 'TAKEOUT',
  TABLE_SERVICE = 'TABLE_SERVICE',
}

export enum DriveThruLaneType {
  DUAL = 'dual',
  SINGLE = 'single',
}

export enum PaymentTypes {
  AMEX = 'Amex',
  APPLE_PAY = 'Apple Pay',
  DISCOVER = 'Discover',
  GOOGLE_PAY = 'Google Pay',
  MASTERCARD = 'MasterCard',
  VISA = 'VISA',
  GIFT_CARD = 'Gift Card',
  CASH = 'Cash payment',
}

export enum LoyaltyChannel {
  ORDERS = 'ORDERS',
  IN_RESTAURANT_SCANS = 'IN_RESTAURANT_SCANS',
  RECEIPT = 'RECEIPT',
}
