import { useCallback, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import uuidValidate from 'uuid-validate';

import {
  lookupCustomerByEmail,
  lookupCustomerByCustomerId,
  lookupPrepaidByCardNumber,
  lookupOrderById,
  searchStores,
  latestOrderByDeviceId,
} from 'remote/queries';
import { EMAIL_REGEX } from 'utils';

import { SearchResult } from './types';

export { searchResultDisplayMap } from './search-result-display-map';
export type { SearchResult };

export const useUniversalSearch = (
  searchTerm: string,
): {
  loading: boolean;
  hits: SearchResult[];
} => {
  const { data: prepaidByCardNumberData, loading: prepaidByCardNumberLoading } = useQuery(
    lookupPrepaidByCardNumber,
    {
      variables: { cardNumber: searchTerm.replace(/-/g, '') },
      skip: searchTerm.replace(/-/g, '').length !== 16 || EMAIL_REGEX.test(searchTerm),
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );
  const { data: searchStoresData, loading: searchStoresLoading } = useQuery(searchStores, {
    variables: { searchTerm },
    skip: !searchTerm || EMAIL_REGEX.test(searchTerm),
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });
  const { data: customerByEmailData, loading: customerByEmailLoading } = useQuery(
    lookupCustomerByEmail,
    {
      variables: { email: searchTerm },
      skip: !EMAIL_REGEX.test(searchTerm),
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );
  const { data: customerByCustomerIdData, loading: customerByCustomerIdLoading } = useQuery(
    lookupCustomerByCustomerId,
    {
      variables: { customerId: searchTerm },
      skip: !uuidValidate(searchTerm) || EMAIL_REGEX.test(searchTerm),
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );
  const { data: orderByIdData, loading: orderByIdLoading } = useQuery(lookupOrderById, {
    variables: { rbiOrderId: searchTerm },
    skip: !uuidValidate(searchTerm) || EMAIL_REGEX.test(searchTerm),
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });
  const { data: orderByDeviceIdData, loading: orderByDeviceIdLoading } = useQuery(
    latestOrderByDeviceId,
    {
      variables: { deviceId: searchTerm },
      skip: !searchTerm || EMAIL_REGEX.test(searchTerm),
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );

  const loading =
    customerByEmailLoading ||
    customerByCustomerIdLoading ||
    orderByIdLoading ||
    searchStoresLoading ||
    prepaidByCardNumberLoading ||
    orderByDeviceIdLoading;

  const hits = [
    customerByEmailData?.Customer,
    customerByCustomerIdData?.Customer,
    orderByIdData?.Order,
    orderByIdData?.Order?.customer,
    ...(searchStoresData?.stores ?? []),
    prepaidByCardNumberData?.prepaidByCardNumber,
  ].filter(Boolean);

  // Filter out ordersByDeviceId if there are no orders
  if ((orderByDeviceIdData?.ordersByDeviceId?.orders?.length ?? 0) > 0) {
    hits.push(orderByDeviceIdData?.ordersByDeviceId);
  }

  return { loading, hits };
};

export const useUniversalSearchFocus = (): {
  focused: boolean;
  setFocused: Function;
} => {
  const [focused, setFocused] = useState(false);
  const handleFocused = useCallback((isFocused) => {
    setFocused(isFocused);
  }, []);

  return { focused, setFocused: handleFocused };
};
