import { MouseEvent } from 'react';

import { Link } from '@reach/router';
import { ListItem } from 'design-system';

import { searchResultDisplayMap } from 'hooks/use-universal-search';
import { useLocationContext } from 'state/location';
import { nestedResources } from 'utils/routing';

export const SearchItem = ({
  item,
  onClick,
}: {
  item: { __typename: string; id: string };
  onClick: Function;
}) => {
  const { location } = useLocationContext();

  const getItemConfig = (item: { __typename: string; id: string }) => {
    const { icon, display, tooltip, route } = searchResultDisplayMap[item.__typename];

    let targetUrl = route(item) || '';
    const targetPathParts = targetUrl.split('/').filter(Boolean);
    const baseResource = `/${targetPathParts[0] ?? ''}`;
    // grab the last part of the url /customers/abc123/orders
    // or just /orders
    // in either case the currentResource will be orders
    const currentResource = location.pathname.split('/').filter(Boolean).slice(-1)[0];

    const currentResourceIsOffers = currentResource?.startsWith('offers');

    // customer/abc123/orders is considered nested for this purpose,
    // but double nested pages like customer/abc123/orders/def456 are not
    const isCurrentResourceNested =
      (targetPathParts.length < 4 && nestedResources[baseResource]?.includes(currentResource)) ??
      false;
    if (isCurrentResourceNested) {
      // if the user selects customer/abc123 from the /orders page,
      // navigate to /customer/abc123/orders
      if (baseResource === '/device-orders') {
        targetUrl += `/`;
      } else if (currentResourceIsOffers) {
        targetUrl += `/offers`;
      } else {
        targetUrl += `/${currentResource}`;
      }
    }
    return { icon, display, tooltip, targetUrl };
  };

  const { icon, display, tooltip, targetUrl } = getItemConfig(item);

  return (
    <ListItem
      button
      component={Link}
      icon={icon(item)}
      iconTooltipText={tooltip(item)}
      onClick={(event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        onClick(targetUrl);
      }}
      text={display(item)}
      to={targetUrl}
    />
  );
};
