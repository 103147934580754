import gql from 'graphql-tag';

export const GetDeviceOrders = gql`
  query GetDeviceOrders(
    $deviceId: String!
    $ordersResumeAfter: String
    $limit: Int
    $sortAscending: Boolean
  ) {
    ordersByDeviceId(
      deviceId: $deviceId
      resumeAfter: $ordersResumeAfter
      limit: $limit
      sortAscending: $sortAscending
    ) {
      deviceId
      count
      orders {
        id: rbiOrderId
        createdAt
        cart {
          totalCents
          serviceMode
          storeAddress {
            country
          }
          storeDetails {
            timezone
          }
        }
        delivery {
          status
        }
        order {
          refund {
            approvedAmount
            refundedItems {
              name
            }
            adminUserEmail
          }
        }
        loyaltyTransaction {
          pointsEarned
          pointsBalance
          pointsUsed
          bonusPointsEarned
          rewardsUsed
        }
        paymentStatus
        paymentVersion
        status
      }
      resumeAfter
    }
  }
`;
